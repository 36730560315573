
import { defineComponent } from 'vue'
import AdminPanelNavMenu from '@/components/molecules/AdminPanelNavMenu.vue'
import SortArrow from '@/assets/svg/sort-arrow.svg?inline'
import AnaliticMenuSVG from '@/assets/svg/analitic-menu.svg?inline'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import Pagination from '@/components/molecules/Pagination.vue'
import AnalyticsNavMenu from '@/components/molecules/AnalyticsNavMenu.vue'
import Select from '@/components/atoms/CustomSelect.vue'

export default defineComponent({
  name: 'TimeSpentInProgram',
  data () {
    return {
      page: 1,
      analyticsData: {},
      analyticsMeta: null,
      erroranAlytics: null,
      searchData: '',
      sortData: '',
      isModalDelete: false,
      idInstructor: null,
      dataAllInstructors: [],
      dataAllStudents: [],
      dataLevels: [],
      filters: '',
      filtersLevel: '',
      isMenuActive: false
    }
  },
  components: {
    AdminPanelNavMenu,
    AnalyticsNavMenu,
    SortArrow,
    DefaultPopup,
    AlertBlock,
    Pagination,
    AnaliticMenuSVG,
    Select
  },
  mounted () {
    this.getAnalytics()
    this.getLevels()
    this.getInstructors()
    this.getStudents()
  },
  methods: {
    getAnalytics (params, sort) {
      let searchParam = `?page=${this.page}&n=20&search=`
      if (this.searchData !== '') {
        searchParam = `?page=${this.page}&search=` + this.searchData
      }
      if (sort === undefined) {
        sort = 'id'
      }
      params = searchParam + '&sort=' + sort + this.filters + this.filtersLevel
      this.$store.dispatch('analytics/timeSpentInProgram', params).then(
        (res) => {
          this.analyticsData = res.data
          this.analyticsMeta = res.meta
        },
        (error) => {
          this.erroranalytics = error.response.data.errors
        }
      )
    },
    getInstructors () {
      const params = '?n=100'
      this.$store.dispatch('instructors/getInstructors', params).then(
        (res) => {
          this.dataAllInstructors = res.data
        },
        (error) => {
          this.errorInstructors = error.response.data.errors
        }
      )
    },
    getStudents () {
      const params = '?n=200'
      this.$store.dispatch('students/getStudents', params).then(
        (res) => {
          this.dataAllStudents = res.data
        },
        (error) => {
          this.errorInstructors = error.response.data.errors
        }
      )
    },
    getLevels () {
      this.$store.dispatch('general/getLevels').then(
        (res) => {
          this.dataLevels = res.data
        },
        (error) => {
          this.errorInstructor = error.response.data.errors
        }
      )
    },
    handlePage (page) {
      this.page = page
      this.getAnalytics()
    },
    handleOptionSelected (selectedOption) {
      console.log(selectedOption)
      this.filters = `&filter[instructor_id]=${selectedOption.id}`
      this.getAnalytics()
    },
    handleOptionSelectedStudent (selectedOption) {
      console.log(selectedOption)
      this.filtersLevel = `&filter[student_id]=${selectedOption.id}`
      this.getAnalytics()
    },
    handleOptionSelectedLevels (selectedOption) {
      console.log(selectedOption)
      this.filtersLevel = `&filter[level_id]=${selectedOption.id}`
      this.getAnalytics()
    },
    resetFilters () {
      window.location.reload()
    },
    toggleMenu () {
      this.isMenuActive = !this.isMenuActive
    },
    closeMenu () {
      this.isMenuActive = false
    }
  }
})
